/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body,
#root {
  height: 100%;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}
#tour-form input {
  height: 40px;
  border-radius: 8px;
}
#tour-form button.ant-switch {
  width: 75px;
  height: 30px;
  border-radius: 25px;
}
#tour-form .ant-switch-handle {
  top: 6px;
  left: 6px;
}
#tour-form .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 6px);
}
#tour-form textarea {
  background: rgba(245, 247, 249, 0.5);
  border-radius: 8px;
}
#tour-form .ant-select-selector,
#tour-form .ant-select-selection-search-input {
  height: 40px;
}
#tour-form .ant-picker input {
  height: auto;
}
#tour-form .ant-select-selection-item,
#tour-form .ant-select-selection-placeholder {
  line-height: 36px;
}
#tour-form .ant-select-multiple .ant-select-selection-item {
  height: 28px;
  margin-bottom: 5px;
  line-height: 22px;
}
#tour-form .ant-select-selector {
  background: #fff;
  border-radius: 8px;
}
#tour-form .ant-upload {
  border-radius: 8px;
}
#tour-form .add-hotel label {
  color: #fff;
}
#tour-form .ant-collapse-item {
  margin-bottom: 12px;
  background: #f5f7f9;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
#tour-form .ant-collapse {
  background: #fff;
  border: none;
}
#tour-form .ant-rate {
  font-size: 16px;
}
#tour-form .place-time {
  align-items: center;
  margin-bottom: 0;
}
#tour-form .place-time label {
  color: #ff3d00;
}
#tour-form .place-row {
  margin: 20px 0;
  padding: 10px;
  background: rgba(245, 247, 249, 0.5);
  border-radius: 8px;
}
#tour-form .ant-input-number {
  width: 100%;
  border-radius: 8px;
}
#tour-form .delete-age {
  margin-top: 15px;
  text-align: center;
}
#tour-form button {
  height: 40px;
  border-radius: 8px;
}
#tour-form .price-name {
  margin-bottom: 0;
}
#tour-form .price-name input {
  height: 30px;
  margin-top: -5px;
  padding: 0;
  background: #fff;
  border: navajowhite;
}
#tour-form .price-name .ant-form-item-control-input {
  min-height: 29px;
}
#tour-form .price-name input:focus {
  box-shadow: none;
}
#tour-form .ant-picker {
  width: 100%;
  height: 40px;
  background: rgba(245, 247, 249, 0.5);
  border-radius: 8px;
}
.ant-upload-draggable-list-item {
  border-top: 2px dashed rgba(0, 0, 0, 0);
  border-bottom: 2px dashed rgba(0, 0, 0, 0);
}
.ant-upload-draggable-list-item.drop-over-downward {
  border-bottom-color: #1890ff;
}
.ant-upload-draggable-list-item.drop-over-upward {
  border-top-color: #1890ff;
}
#tour-form .ant-radio-group label {
  height: 40px;
}
#tour-form .ant-radio-group span {
  line-height: 37px;
}
#tour-form .ant-radio-group label:first-child {
  border-radius: 8px 0 0 8px;
}
#tour-form .ant-radio-group label:last-child {
  border-radius: 0 8px 8px 0;
}
.ant-modal-content {
  border-radius: 14px !important;
}
.ant-alert {
  border-radius: 8px;
}
.ant-input-affix-wrapper {
  background: rgba(245, 247, 249, 0.5);
  border-radius: 8px;
}
.ant-modal {
  top: 35px !important;
}
.ant-modal-header {
  border-radius: 14px 14px 0 0 !important;
}
.ant-modal-footer {
  text-align: center !important;
}
.ant-modal-footer button {
  height: 40px;
  border-radius: 8px;
}
#tour-form .ant-input-group-addon button {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
#tour-form .ant-input-group input {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
#tour-form .ant-input-affix-wrapper {
  border-radius: 12px;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 2px 8px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
#customerDetail .ant-col-12 {
  margin-bottom: 5px;
}
.departure-item {
  flex-flow: row nowrap;
  width: 100%;
}
.departure-item .ant-col-3 {
  min-width: 135px;
}
.departure-item .ant-col-2 {
  min-width: 135px;
}
.tour-sell-apply .ant-col-4 {
  min-width: 165px;
}
.ant-row ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.ant-row ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}
.table-custom .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 8px !important;
}
#blog-avatar .ant-upload-list {
  display: none;
}
#blog-avatar .ant-upload-select {
  position: relative;
  z-index: 1000;
}
#blog-avatar div.ant-upload {
  position: absolute;
  top: 55px;
  margin-left: calc(50% - 80px);
  color: #fff !important;
  text-align: center;
}
#blog-avatar button {
  height: 66px;
}
#tour-form .ant-input-number-group-addon {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
#tour-form .ant-input-number-group-wrapper .ant-input-number-in-form-item {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#home-tabs .ant-tabs-tab-btn {
  color: #fc8635;
  font-weight: 600;
  font-size: 17px;
}
#home-tabs .ant-tabs-ink-bar {
  background: #fc8635;
}
.ant-btn-primary {
  background: #1BA4E8;
  border-color: #1BA4E8;
}
.tox-tinymce button {
  height: auto !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 8px !important;
}
#dashboard-top .ant-card-body {
  padding: 0;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #0093DC;
  background: #0093DC;
}
.ant-pro-sider.ant-layout-sider-light .ant-menu-item-selected a,
.ant-pro-sider.ant-layout-sider-light .ant-menu-item a:hover {
  color: #0093DC;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0093DC;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  height: 24px !important;
}
#tour-form .ant-input-affix-wrapper {
  padding: 0 11px;
}
#tour-form .ant-input-affix-wrapper input {
  height: 38px;
}
#tour-form .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}
